import { titlifyEnum } from '@utils/stringHelpers';
import { getFullName } from '~/components/utils';
import { FAIcon } from '~/components/utils/FAIcons';
import { JobLogClientEntry } from '~/server/schema/jobLog.schema';
import { getJobLogColor } from '../../jobs/job-log/JobLogEntry';
import { getFileIcon } from '@utils/browserFileHandling';
import Link from 'next/link';

interface Props {
	jobLog: JobLogClientEntry;
	logIndex: number;
	logLength: number;
}

export const DashboardJobLogItem = ({ jobLog, logIndex, logLength }: Props) => {
	const colors = getJobLogColor(jobLog);

	if (!jobLog.logType) return null;

	return (
		<div className="relative my-2 space-y-2">
			{logIndex !== logLength - 1 ? <span className="absolute top-6 left-3 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true" /> : null}
			<div className="relative flex items-start justify-between space-x-3">
				<div className="pt-0.5">
					<span className={`${colors ? colors : 'bg-primary text-white'} h-6 w-6 text-sm rounded-full flex items-center justify-center`}>
						{jobLog.icon && <FAIcon icon={jobLog.icon} />}
					</span>
				</div>
				<div className="flex min-w-0 flex-1 justify-between space-x-4 text-sm">
					<div className="space-y-0.5">
						<h3 className="font-semibold text-gray-900">{titlifyEnum(jobLog.logType)}</h3>
						<span className="font-medium text-primary">
							<Link href={`/jobs/${jobLog.jobId}`} legacyBehavior>
								{jobLog.jobName}
							</Link>
						</span>
						<p className="text-gray-700 whitespace-pre-wrap">{jobLog.summary}</p>
						{jobLog.user && (
							<p className="text-gray-500">
								Completed by <span className="font-medium">{getFullName(jobLog.user)}</span>
							</p>
						)}
					</div>
					<div className="whitespace-nowrap text-right text-xs text-gray-600">
						<p>{jobLog.time}</p>
					</div>
				</div>
			</div>
			{!!jobLog.documents?.length && (
				<div className="ml-9 space-y-2">
					{jobLog.documents.map((document) => (
						<div key={document.id} className="h-[150px] w-full flex items-center justify-center bg-gray-200 overflow-hidden rounded-md">
							{document.source && getFileIcon(document.fileType).type === 'image' && (
								<div className="h-full w-full bg-cover relative" style={{ backgroundImage: `url(${document.source})` }}>
									<a
										className="absolute top-2 right-2 bg-gray-800 text-white px-2.5 py-1 rounded-lg bg-opacity-60"
										target="_blank"
										rel="noreferrer"
										href={document.source}
									>
										<FAIcon icon="eye" />{' '}
									</a>
								</div>
							)}
						</div>
					))}
				</div>
			)}
		</div>
	);
};
