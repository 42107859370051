import { Branch } from '@prisma/client';
import { Dispatch, SetStateAction } from 'react';
import JobSearchComboBox from '~/components/form-fields/custom/JobSearchComboBox';
import StatelessDropDownButton from '~/components/utils/buttons/StatelessDropDownButton';
import { CreateJobButton } from '../jobs/CreateJobButton';
import { DashboardPageToggle } from './DashboardPageToggle';

interface Props {
	branches?: Branch[];
	activeBranches: string[];
	setActiveBranches: Dispatch<SetStateAction<string[]>>;
}

export const DashboardHeader = ({ branches, activeBranches, setActiveBranches }: Props) => {
	return (
		<div className="flex w-full p-4 justify-between items-center bg-white">
			<DashboardPageToggle />
			<div className="w-2/3 flex items-center space-x-2">
				<JobSearchComboBox />
				{!!branches?.length && (
					<StatelessDropDownButton
						label="Branch"
						options={branches.map((branch) => ({ text: branch.name, value: branch.id, active: false }))}
						className="bg-transparent px-2 hover:bg-gray-200 w-40"
						activeOptions={activeBranches}
						sendSelections={setActiveBranches}
						showActive={true}
					/>
				)}
				<CreateJobButton />
			</div>
		</div>
	);
};
