export class CONSTANTS {
	public static readonly defaultTimezone = 'America/New_York';

	public static readonly defaultLimitInfinite = 10;
	public static readonly defaultLimitEmptySearch = 20;

	public static CLOUDFRONT_URL = `https://d272jmvoweunhk.cloudfront.net/`;

	public static readonly METERS_TO_MILES = 0.000621371192;
}
