import { FAIcon } from './utils/FAIcons';

type Props = {
	size?: string;
};

export const LoadingCircle = ({ size = 'text-3xl' }: Props) => {
	return (
		<div className={`flex justify-center ${size} text-gray-500`}>
			<FAIcon prefix="fal" icon="spinner-third" faClassName="fa-spin" />
		</div>
	);
};
