import { JobLogType, JobPhase, PurchaseOrderStatus, ScheduledJobType } from '@prisma/client';
import { useInView } from 'react-intersection-observer';
import useInfinite from '~/hooks/useInfinite';
import { DashboardPurchaseOrderItem } from '../DashboardItems/DashboardPurchaseOrderItem';
import { DashboardCard } from './DashboardCard';
import { DateHelper } from '@utils/date.helper';
import { CONSTANTS } from '@utils/constants';

interface Props {
	activeBranchIds: number[];
}

export const DashboardPurchaseOrdersCheckedInCard = ({ activeBranchIds }: Props) => {
	const { ref, inView } = useInView();

	const {
		query: { isLoading },
		data: purchaseOrders,
		fetchNextPage,
	} = useInfinite(
		'purchaseOrders',
		'allForDashboard',
		{
			limit: CONSTANTS.defaultLimitInfinite,
			selectedStatuses: [
				PurchaseOrderStatus.FINALIZED,
				PurchaseOrderStatus.ACCEPTED,
				PurchaseOrderStatus.INVOICE_NEEDS_PAYMENT,
				PurchaseOrderStatus.PAID,
			],
			jobPhases: [JobPhase.ESTIMATE, JobPhase.WORK_ORDER],
			lastLogTypes: [JobLogType.PO_ACCEPTED, JobLogType.PO_FINALIZED, JobLogType.PO_PAID],
			disallowedScheduledJobTypes: [ScheduledJobType.INSTALL],
			branchIds: activeBranchIds,
			orderBy: { updatedAt: 'desc' },
		},
		{ enabled: inView }
	);

	return (
		<DashboardCard ref={ref} icon="cart-circle-check" title="POs checked in" isLoading={isLoading} fetchNextPage={fetchNextPage}>
			<div className="space-y-3">
				{purchaseOrders.map((purchaseOrder) => {
					const displayDate =
						purchaseOrder.actualArrivalDate === null ? undefined : DateHelper.formatAsTodayOrDate(purchaseOrder.actualArrivalDate);

					return (
						<DashboardPurchaseOrderItem
							key={purchaseOrder.id}
							purchaseOrder={purchaseOrder}
							displayDate={displayDate}
							backgroundColors="bg-green-100 hover:bg-green-200"
						/>
					);
				})}
			</div>
		</DashboardCard>
	);
};
