import { JobPhase } from '@prisma/client';
import { useInView } from 'react-intersection-observer';
import useInfinite from '~/hooks/useInfinite';
import { DashboardJobItem } from '../DashboardItems/DashboardJobItem';
import { DashboardCard } from './DashboardCard';
import { CONSTANTS } from '@utils/constants';

interface Props {
	activeBranchIds: number[];
}

export const DashboardFlaggedJobsCard = ({ activeBranchIds }: Props) => {
	const { ref, inView } = useInView();

	const {
		query: { isLoading },
		data: jobs,
		fetchNextPage,
	} = useInfinite(
		'jobs',
		'allForDashboard',
		{
			filters: { phases: [JobPhase.ESTIMATE, JobPhase.WORK_ORDER], requireProblemReason: true, branchIds: activeBranchIds },
			sortBy: 'Updated At',
			limit: CONSTANTS.defaultLimitInfinite,
		},
		{ enabled: inView }
	);

	return (
		<DashboardCard ref={ref} icon="flag-swallowtail" title="Flagged jobs" isLoading={isLoading} fetchNextPage={fetchNextPage}>
			<div className="space-y-3">
				{jobs.map((job) => (
					<DashboardJobItem key={job.id} job={job} subtitle={job.problemReason} />
				))}
			</div>
		</DashboardCard>
	);
};
