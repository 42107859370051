import { useInView } from 'react-intersection-observer';
import useInfinite from '~/hooks/useInfinite';
import { DashboardTransactionItem } from '../DashboardItems/DashboardTransactionItem';
import { DashboardCard } from './DashboardCard';
import { CONSTANTS } from '@utils/constants';

interface Props {
	activeBranchIds: number[];
}

export const DashboardRecentPaymentsCard = ({ activeBranchIds }: Props) => {
	const { ref, inView } = useInView();

	const {
		query: { isLoading },
		data: transactions,
		fetchNextPage,
	} = useInfinite(
		'transaction',
		'allForDashboard',
		{
			limit: CONSTANTS.defaultLimitInfinite,
			branchIds: activeBranchIds,
		},
		{ enabled: inView }
	);

	return (
		<DashboardCard ref={ref} icon="circle-dollar-to-slot" title="Recent payments" isLoading={isLoading} fetchNextPage={fetchNextPage}>
			<div className="space-y-3">
				{transactions.map((transaction) => (
					<DashboardTransactionItem key={transaction.id} transaction={transaction} job={transaction.job} />
				))}
			</div>
		</DashboardCard>
	);
};
