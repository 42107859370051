import { useInView } from 'react-intersection-observer';
import useInfinite from '~/hooks/useInfinite';
import { DashboardQuoteTaskItem } from '../DashboardItems/DashboardQuoteTaskItem';
import { DashboardCard } from './DashboardCard';
import { CONSTANTS } from '@utils/constants';

interface Props {
	activeBranchIds: number[];
}

export const DashboardQuotesToFollowUpCard = ({ activeBranchIds }: Props) => {
	const { ref, inView } = useInView();

	const {
		query: { isLoading },
		data: tasks,
		fetchNextPage,
	} = useInfinite(
		'tasks',
		'allForQuoteDashboard',
		{
			limit: CONSTANTS.defaultLimitInfinite,
			branchIds: activeBranchIds,
		},
		{ enabled: inView }
	);

	return (
		<DashboardCard ref={ref} icon="file-circle-question" title="Quotes to follow up" isLoading={isLoading} fetchNextPage={fetchNextPage}>
			<div className="space-y-3">
				{tasks.map((task) => (
					<DashboardQuoteTaskItem key={task.id} task={task} job={task.job} quote={task.job.quotes[0]} />
				))}
			</div>
		</DashboardCard>
	);
};
