import { CONSTANTS } from '@utils/constants';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import TabGroup from '~/components/navigation/TabGroup';
import useInfinite from '~/hooks/useInfinite';
import { DashboardKeyDateItem } from '../DashboardItems/DashboardKeyDateItem';
import { DashboardCard } from './DashboardCard';
import { trpc } from '@utils/trpc';
import Badge from '~/components/utils/Badge';

interface Props {
	activeBranchIds: number[];
}

export const DashboardKeyDatesCard = ({ activeBranchIds }: Props) => {
	const { ref, inView } = useInView();

	const [type, setType] = useState<'OVERDUE' | 'UPCOMING'>('OVERDUE');

	const {
		query: { isLoading },
		data: keyDates,
		fetchNextPage,
	} = useInfinite(
		'keyDate',
		'allForDashboard',
		{
			branchIds: activeBranchIds,
			limit: CONSTANTS.defaultLimitInfinite,
			type,
		},
		{ enabled: inView }
	);

	const { data: counts } = trpc.keyDate.countsForDashboard.useQuery({ branchIds: activeBranchIds });

	return (
		<DashboardCard
			ref={ref}
			icon="calendar"
			title="Key Dates"
			mainContentHeight="h-[276px]"
			headerContent={
				<div className="h-[43px]">
					<TabGroup
						tabs={[
							{
								value: 'OVERDUE',
								tab: (
									<span className="flex gap-x-2">
										Overdue
										{!!counts && (
											<Badge size="sm" colors="bg-red-600 text-white">
												{counts.overdueCount}
											</Badge>
										)}
									</span>
								),
							},
							{
								value: 'UPCOMING',
								tab: (
									<span className="flex gap-x-2">
										Upcoming
										{!!counts && (
											<Badge size="sm" colors="bg-orange-600 text-white">
												{counts.upcomingCount}
											</Badge>
										)}
									</span>
								),
							},
						]}
						onActiveTabChange={setType}
						activeTab={type}
						colors={{
							active: type === 'OVERDUE' ? 'border-red-600 text-red-600' : 'border-orange-600 text-orange-600',
							inactive: 'text-gray-500 hover:border-gray-300 hover:text-gray-700 border-transparent',
						}}
					/>
				</div>
			}
			isLoading={isLoading}
			fetchNextPage={fetchNextPage}
		>
			<div className="space-y-3">
				{keyDates.map((keyDate) => (
					<DashboardKeyDateItem key={keyDate.row_number} keyDate={keyDate} color={type === 'OVERDUE' ? 'bg-red-600' : 'bg-orange-600'} />
				))}
			</div>
		</DashboardCard>
	);
};
