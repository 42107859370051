import { EstimateState, JobPhase } from '@prisma/client';
import { formatUSD } from '@utils/stringHelpers';
import { useInView } from 'react-intersection-observer';
import useInfinite from '~/hooks/useInfinite';
import { DashboardJobItem } from '../DashboardItems/DashboardJobItem';
import { DashboardCard } from './DashboardCard';
import { CONSTANTS } from '@utils/constants';

interface Props {
	activeBranchIds: number[];
}

export const DashboardWonJobsCard = ({ activeBranchIds }: Props) => {
	const { ref, inView } = useInView();

	const {
		query: { isLoading },
		data: jobs,
		fetchNextPage,
	} = useInfinite(
		'jobs',
		'allForDashboard',
		{
			filters: {
				phases: [JobPhase.WORK_ORDER],
				branchIds: activeBranchIds,
			},
			sortBy: 'Updated At',
			limit: CONSTANTS.defaultLimitInfinite,
		},
		{ enabled: inView }
	);

	return (
		<DashboardCard ref={ref} icon="party-horn" title="Won jobs" isLoading={isLoading} fetchNextPage={fetchNextPage}>
			<div className="space-y-3">
				{jobs.map((job) => (
					<DashboardJobItem key={job.id} job={job} subtitle={`Balance: ${formatUSD(job.estimateBalance)}`} />
				))}
			</div>
		</DashboardCard>
	);
};
