import { titlifyEnum } from '@utils/stringHelpers';
import { useInView } from 'react-intersection-observer';
import { DashboardJobItem } from '../DashboardItems/DashboardJobItem';
import { DashboardCard } from './DashboardCard';
import { trpc } from '@utils/trpc';

interface Props {
	activeBranchIds: number[];
}

export const DashboardDepositPaidOrApprovedCard = ({ activeBranchIds }: Props) => {
	const { ref, inView } = useInView();

	const { data, isLoading } = trpc.analytics.depositPaid.useQuery(
		{
			branchIds: activeBranchIds,
		},
		{
			enabled: inView,
		}
	);

	return (
		<DashboardCard ref={ref} icon="hand-holding-dollar" title="Deposit paid" isLoading={isLoading}>
			<div className="space-y-3">
				{data?.map((value) => {
					return (
						<DashboardJobItem
							key={value.id}
							job={value.job}
							idBorderColor="border-green-300"
							alternativeBadge={
								!!value.job.lastStatus && (
									<div className="bg-green-50 border border-green-500 text-green-900 rounded px-2 py-0.5 ml-2">
										{titlifyEnum(value.job.lastStatus)}
									</div>
								)
							}
						/>
					);
				})}
			</div>
		</DashboardCard>
	);
};
