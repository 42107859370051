import { useInView } from 'react-intersection-observer';
import useInfinite from '~/hooks/useInfinite';
import { DashboardPurchaseOrderItem } from '../DashboardItems/DashboardPurchaseOrderItem';
import { DashboardCard } from './DashboardCard';
import { DateHelper } from '@utils/date.helper';
import { CONSTANTS } from '@utils/constants';

interface Props {
	activeBranchIds: number[];
}

export const DashboardPurchaseOrdersArrivingSoon = ({ activeBranchIds }: Props) => {
	const { ref, inView } = useInView();

	const {
		query: { isLoading },
		data: purchaseOrders,
		fetchNextPage,
	} = useInfinite(
		'purchaseOrders',
		'allForDashboard',
		{
			limit: CONSTANTS.defaultLimitInfinite,
			selectedStatuses: ['AWAITING_DELIVERY'],
			isArrivingSoon: true,
			branchIds: activeBranchIds,
			orderBy: { expectedArrivalDate: 'asc' },
		},
		{ enabled: inView }
	);

	return (
		<DashboardCard ref={ref} icon="cart-arrow-up" title="POs coming in this week" isLoading={isLoading} fetchNextPage={fetchNextPage}>
			<div className="space-y-3">
				{purchaseOrders.map((purchaseOrder) => {
					const displayDate =
						purchaseOrder.expectedArrivalDate === null ? undefined : DateHelper.formatAsTodayOrDate(purchaseOrder.expectedArrivalDate);

					return (
						<DashboardPurchaseOrderItem
							key={purchaseOrder.id}
							purchaseOrder={purchaseOrder}
							displayDate={displayDate}
							backgroundColors="bg-indigo-50 hover:bg-indigo-100"
						/>
					);
				})}
			</div>
		</DashboardCard>
	);
};
