import { JobLogType } from '@prisma/client';
import { DateHelper } from '@utils/date.helper';
import { titlifyEnum } from '@utils/stringHelpers';
import React from 'react';
import { getFullName } from '~/components/utils';
import { FAIcon } from '~/components/utils/FAIcons';
import { JobLogClientEntry } from '~/server/schema/jobLog.schema';

//Switch statement required as Tailwind requires all possible colors to be present in the file at build time. Can't import from an external source.
export const getJobLogColor = (log: JobLogClientEntry | undefined) => {
	let colors = 'bg-glazier-blue-100 text-primary-dark';
	if (!log) return colors;
	if (
		log.logType?.startsWith('ESTIMATE') ||
		log.logType?.startsWith('INVOICE') ||
		log.logType?.startsWith('DEPOSIT') ||
		log.logType?.startsWith('FINAL_INVOICE') ||
		log.logType === JobLogType.ACCOUNTING_COMPLETE
	) {
		colors = 'bg-green-300 text-green-800';
	} else if (log.logType?.startsWith('PO')) {
		colors = 'bg-purple-200 text-purple-800';
	} else {
		switch (log.logType) {
			case JobLogType.COMMENT_ADDED:
				colors = 'bg-indigo-200 text-indigo-800';
				break;
			case JobLogType.DOCUMENT_ATTACHED:
				colors = 'bg-yellow-200 text-yellow-800';
				break;
			case JobLogType.MEASUREMENT_CREATED:
				colors = 'bg-orange-200 text-orange-800';
				break;
			case JobLogType.WORK_ORDER_CREATED:
				colors = 'bg-orange-200 text-orange-800';
				break;
			case JobLogType.TASK_CREATED:
				colors = 'bg-green-200 text-green-900';
				break;
			case JobLogType.REMINDER_SENT:
				colors = 'bg-blue-200 text-blue-800';
				break;
			case JobLogType.LOG:
				colors = 'bg-primary-dark text-white';
				break;
			case JobLogType.JOB_SCHEDULED:
			case JobLogType.INSTALLATION_COMPLETE:
				colors = 'bg-cyan-200 text-cyan-900';
				break;
		}
	}
	return colors;
};

const JobLogEntry = ({ log, logIdx, logLength }: { log: JobLogClientEntry; logIdx: number; logLength: number }) => {
	const colors = getJobLogColor(log);
	if (!log.logType) {
		return <></>;
	}
	return (
		<li>
			<div className="relative my-2">
				{logIdx !== logLength - 1 ? <span className="absolute top-10 left-5 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true" /> : null}
				<div className="relative flex items-start justify-between space-x-3">
					<div className="pt-0.5">
						{/* <span className={`bg-purple-700 text-white h-10 w-10 text-xl rounded-full flex items-center justify-center`}> */}
						<span
							className={`${colors ? colors : 'bg-primary text-white'} h-10 w-10 text-xl rounded-full flex items-center justify-center`}
						>
							{log.icon && <FAIcon icon={log.icon} />}
						</span>
					</div>
					<div className="flex min-w-0 flex-1 justify-between space-x-4">
						<div>
							<div>
								<h3 className="font-medium">{titlifyEnum(log.logType)}</h3>
								<p className="text-sm text-gray-500">{log.summary}</p>
							</div>
							<div className="mt-2 text-xs flex items-center space-x-2 text-gray-500">
								{log.date && <time dateTime={log.date}>{DateHelper.formatDate(new Date(log.date), false, 'short')}</time>}
								<span>|</span>
								{log.user && (
									<p>
										Completed by <span className="font-bold">{getFullName(log.user)}</span>
									</p>
								)}
							</div>
						</div>
						<div className="whitespace-nowrap text-right text-sm text-gray-500">
							<p>{log.time}</p>
						</div>
					</div>
				</div>
			</div>
		</li>
	);
};

export default JobLogEntry;
