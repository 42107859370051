import { Quote } from '@prisma/client';
import { DateHelper } from '@utils/date.helper';
import { useRouter } from 'next/router';
import { FAIcon } from '~/components/utils/FAIcons';

interface Props {
	jobId: number | null;
	invoice: Quote;
}

export const DashboardInvoiceItem = ({ jobId, invoice }: Props) => {
	const router = useRouter();
	const displayDate = invoice.updatedAt === null ? undefined : DateHelper.formatAsTodayOrDate(invoice.updatedAt);
	const invoiceName = `Invoice: ${invoice.number}`;

	return (
		<div onClick={() => jobId && router.push(`/jobs/${jobId}`)} className="bg-red-50 hover:bg-red-100 rounded-lg p-3 space-y-2 cursor-pointer">
			<div className="flex font-medium text-gray-800 items-start">
				<div>
					<h4 className="font-semibold text-gray-900">{invoiceName}</h4>
					<p className="text-gray-700">{invoice.name}</p>
				</div>

				<div className="flex items-center ml-auto space-x-2">
					{!!displayDate && <div className="text-xs text-gray-600">{displayDate}</div>}
					<div className="text-sm text-gray-400">
						<FAIcon icon="arrow-up-right-from-square" />
					</div>
				</div>
			</div>
		</div>
	);
};
