import { useInView } from 'react-intersection-observer';
import useInfinite from '~/hooks/useInfinite';
import { DashboardMessageItem } from '../DashboardItems/DashboardMessageItem';
import { DashboardCard } from './DashboardCard';
import { CONSTANTS } from '@utils/constants';

interface Props {
	activeBranchIds: number[];
}

export const DashboardRecentCustomerTextsCard = ({ activeBranchIds }: Props) => {
	const { ref, inView } = useInView();

	const {
		query: { isLoading },
		data: messages,
		fetchNextPage,
	} = useInfinite(
		'sms',
		'allForDashboard',
		{
			limit: CONSTANTS.defaultLimitInfinite,
			branchIds: activeBranchIds,
		},
		{ enabled: inView }
	);

	return (
		<DashboardCard ref={ref} icon="messages" title="Recent customer texts" isLoading={isLoading} fetchNextPage={fetchNextPage}>
			<div className="space-y-3">
				{messages.map((message) => (
					<DashboardMessageItem key={message.id} message={message} job={message.job} customer={message.job?.customer} />
				))}
			</div>
		</DashboardCard>
	);
};
