import { DashboardCard } from './DashboardCard';
import { trpc } from '@utils/trpc';
import { useInView } from 'react-intersection-observer';
import { DashboardInvoiceItem } from '../DashboardItems/DashboardInvoiceItem';

interface Props {
	activeBranchIds: number[];
}

export const DashboardPastDueCustomerInvoicesCard = ({ activeBranchIds }: Props) => {
	const { ref, inView } = useInView();

	const { data, isLoading } = trpc.analytics.pastDueCustomerInvoices.useQuery(
		{
			branchIds: activeBranchIds,
		},
		{
			enabled: inView,
		}
	);

	return (
		<DashboardCard ref={ref} icon="file-exclamation" title="Past due customer invoices" isLoading={isLoading}>
			<div className="space-y-3">
				{data?.invoices?.map((invoice) => <DashboardInvoiceItem key={invoice.id} jobId={invoice.jobId} invoice={invoice} />)}
			</div>
		</DashboardCard>
	);
};
