import { DashboardCard } from './DashboardCard';
import { DashboardJobItem } from '../DashboardItems/DashboardJobItem';
import { formatUSD } from '@utils/stringHelpers';
import { trpc } from '@utils/trpc';
import { useInView } from 'react-intersection-observer';

interface Props {
	activeBranchIds: number[];
}

export const DashboardRecentCompletedMeasuresCard = ({ activeBranchIds }: Props) => {
	const { ref, inView } = useInView();

	const { data, isLoading } = trpc.analytics.recentlyCompletedMeasures.useQuery(
		{
			branchIds: activeBranchIds,
		},
		{
			enabled: inView,
		}
	);

	const subTitle = data?.totalAmount ? `Total: ${formatUSD(data.totalAmount)}` : undefined;

	return (
		<DashboardCard
			ref={ref}
			icon="bell"
			title="Recently completed measures"
			subTitle={subTitle}
			isLoading={isLoading}
			mainContentHeight="h-[300px]"
		>
			<div className="space-y-3">
				{data?.jobs?.map((job) => <DashboardJobItem key={job.id} job={job} subtitle={`Balance: ${formatUSD(job.balance)}`} />)}
			</div>
		</DashboardCard>
	);
};
