import { DashboardDelayedPurchaseOrdersCard } from './DashboardCards/DashboardDelayedPurchaseOrdersCard';
import { DashboardDepositPaidOrApprovedCard } from './DashboardCards/DashboardDepositPaidOrApprovedCard';
import { DashboardFeedCard } from './DashboardCards/DashboardFeedCard';
import { DashboardFlaggedJobsCard } from './DashboardCards/DashboardFlaggedJobsCard';
import { DashboardKeyDatesCard } from './DashboardCards/DashboardKeyDatesCard';
import { DashboardPastDueCustomerInvoicesCard } from './DashboardCards/DashboardPastDueCustomerInvoice';
import { DashboardPurchaseOrdersArrivingSoon } from './DashboardCards/DashboardPurchaseOrdersArrivingSoonCard';
import { DashboardPurchaseOrdersCheckedInCard } from './DashboardCards/DashboardPurchaseOrdersCheckedInCard';
import { DashboardQuotesToFollowUpCard } from './DashboardCards/DashboardQuotesToFollowUpCard';
import { DashboardRecentCompletedInstallsCard } from './DashboardCards/DashboardRecentCompletedInstall';
import { DashboardRecentCompletedMeasuresCard } from './DashboardCards/DashboardRecentCompletedMeasures';
import { DashboardRecentCustomerTextsCard } from './DashboardCards/DashboardRecentCustomerTextsCard';
import { DashboardRecentPaymentsCard } from './DashboardCards/DashboardRecentPaymentsCard';
import { DashboardRejectedPurchaseOrdersCard } from './DashboardCards/DashboardRejectedPurchaseOrdersCard';
import { DashboardWonJobsCard } from './DashboardCards/DashboardWonJobsCard';

interface Props {
	activeBranchIds: number[];
}

export const DashboardGrid = ({ activeBranchIds }: Props) => {
	return (
		<div className="flex">
			<div className="w-1/2 2xl:w-2/3 grid grid-cols-1 2xl:grid-cols-2 p-6 pr-0 gap-4">
				{/* <DashboardRemindersCard /> */}
				<DashboardKeyDatesCard activeBranchIds={activeBranchIds} />
				<DashboardFlaggedJobsCard activeBranchIds={activeBranchIds} />
				<DashboardWonJobsCard activeBranchIds={activeBranchIds} />
				<DashboardQuotesToFollowUpCard activeBranchIds={activeBranchIds} />
				<DashboardDepositPaidOrApprovedCard activeBranchIds={activeBranchIds} />
				<DashboardPurchaseOrdersCheckedInCard activeBranchIds={activeBranchIds} />
				<DashboardDelayedPurchaseOrdersCard activeBranchIds={activeBranchIds} />
				<DashboardPurchaseOrdersArrivingSoon activeBranchIds={activeBranchIds} />
				<DashboardRejectedPurchaseOrdersCard activeBranchIds={activeBranchIds} />
				<DashboardRecentPaymentsCard activeBranchIds={activeBranchIds} />
				<DashboardRecentCustomerTextsCard activeBranchIds={activeBranchIds} />

				<DashboardRecentCompletedMeasuresCard activeBranchIds={activeBranchIds} />
				<DashboardRecentCompletedInstallsCard activeBranchIds={activeBranchIds} />
				<DashboardPastDueCustomerInvoicesCard activeBranchIds={activeBranchIds} />
			</div>
			<div className="w-1/2 2xl:w-1/3 m-6 ml-4">
				<DashboardFeedCard activeBranchIds={activeBranchIds} />
			</div>
		</div>
	);
};
