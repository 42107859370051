import { Job } from '@prisma/client';
import { DateHelper } from '@utils/date.helper';
import { titlifyEnum } from '@utils/stringHelpers';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { classNames } from '~/components/utils';
import { FAIcon } from '~/components/utils/FAIcons';

interface Props {
	job: Job;
	subtitle?: string | null;
	idBorderColor?: string;
	alternativeBadge?: ReactNode;
}

export const DashboardJobItem = ({ job, subtitle, idBorderColor = 'border-glazier-blue-300', alternativeBadge }: Props) => {
	const router = useRouter();
	const displayDate = job.updatedAt === null ? undefined : DateHelper.formatAsTodayOrDate(job.updatedAt);

	return (
		<div onClick={() => router.push(`/jobs/${job.id}`)} className="bg-gray-50 hover:bg-gray-100 rounded-lg p-3 space-y-2 cursor-pointer">
			<div className="flex text-xs font-medium text-gray-800">
				<div className={classNames('bg-white border rounded px-2 py-0.5', idBorderColor)}>ID: {job.id}</div>
				{!!alternativeBadge ? (
					alternativeBadge
				) : (
					<div className="bg-gray-20 border border-gray-200 rounded px-2 py-0.5 ml-2">{titlifyEnum(job.category)}</div>
				)}
				<div className="flex items-center ml-auto space-x-2">
					{!!displayDate && <div className="text-xs text-gray-600">{displayDate}</div>}
					<div className="text-sm text-gray-400">
						<FAIcon icon="arrow-up-right-from-square" />
					</div>
				</div>
			</div>
			<div className="text-sm space-y-1">
				<h4 className="font-semibold text-gray-900">{job.name}</h4>
				{!!subtitle && <p className="text-gray-700">{subtitle}</p>}
			</div>
		</div>
	);
};
