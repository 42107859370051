import { PurchaseOrderForDashboard } from '@services/purchaseOrder.service';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { classNames } from '~/components/utils';
import { FAIcon } from '~/components/utils/FAIcons';

interface Props {
	purchaseOrder: PurchaseOrderForDashboard;
	displayDate?: string;
	backgroundColors: string;
}

export const DashboardPurchaseOrderItem = ({ purchaseOrder, displayDate, backgroundColors }: Props) => {
	const router = useRouter();

	return (
		<div
			onClick={() => router.push(`/jobs/${purchaseOrder.jobId}/purchase-orders?s=${purchaseOrder.poNumber}`)}
			className={classNames(backgroundColors, 'bg-gray-50 rounded-lg p-3 space-y-2 text-sm cursor-pointer')}
		>
			<div className="flex font-medium text-gray-800">
				<h4 className="font-semibold text-gray-900">{purchaseOrder.poNumber}</h4>
				<div className="flex items-center ml-auto space-x-2">
					{!!displayDate && <div className="text-xs text-gray-600">{displayDate}</div>}
					<div className="text-gray-400">
						<FAIcon icon="arrow-up-right-from-square" />
					</div>
				</div>
			</div>
			<p className="text-gray-700">{purchaseOrder.job.name}</p>
		</div>
	);
};
