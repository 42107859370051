import React, { ReactNode, forwardRef, useCallback, useEffect, useRef } from 'react';
import { LoadingCircle } from '~/components/LoadingCircle';
import { classNames } from '~/components/utils';
import { FAIcon } from '~/components/utils/FAIcons';

interface Props {
	icon: string;
	title: string;
	subTitle?: string;
	isLoading: boolean;
	mainContentHeight?: string;
	headerContent?: ReactNode;
	children?: ReactNode;
	fetchNextPage?: () => void;
	isFullHeight?: boolean;
}

export const DashboardCard = forwardRef<HTMLDivElement, Props>(
	({ icon, title, isLoading, mainContentHeight, headerContent, children, fetchNextPage, subTitle, isFullHeight = false }, ref) => {
		const scrollableAreaRef = useRef<HTMLDivElement>(null);
		const fetchAtBottom = useCallback(() => {
			if (!fetchNextPage || !scrollableAreaRef.current) return;
			const { scrollTop, clientHeight, scrollHeight } = scrollableAreaRef.current;
			if (scrollTop + clientHeight > scrollHeight - 1) {
				fetchNextPage();
			}
		}, [fetchNextPage, scrollableAreaRef.current]);

		useEffect(() => {
			if (fetchNextPage && scrollableAreaRef.current) {
				const _scrollableAreaRef = scrollableAreaRef.current;
				_scrollableAreaRef.addEventListener('scroll', fetchAtBottom);
				return () => _scrollableAreaRef.removeEventListener('scroll', fetchAtBottom);
			}
		}, [fetchNextPage, fetchAtBottom]);

		return (
			<div ref={ref} className={classNames(isFullHeight ? 'h-[5584px] 2xl:h-[2784px]' : 'h-96', 'bg-white drop-shadow rounded-xl')}>
				<div className="flex items-center space-x-3 p-4 text-base">
					<div className="h-8 w-8 bg-gray-100 rounded-md flex items-center justify-center">
						<FAIcon icon={icon} />
					</div>
					<h2 className="font-semibold">{title}</h2>
					{!!subTitle && <div className="text-gray-500">{subTitle}</div>}
				</div>
				{headerContent}
				<hr className="border-gray-100" />
				<div
					ref={scrollableAreaRef}
					className={classNames(
						isFullHeight ? 'h-[5520px] 2xl:h-[2720px]' : mainContentHeight ? mainContentHeight : 'h-80',
						'p-4 overflow-scroll'
					)}
				>
					{isLoading ? <LoadingCircle /> : children}
				</div>
			</div>
		);
	}
);
