import { DateHelper } from '@utils/date.helper';
import { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import useInfinite from '~/hooks/useInfinite';
import { DashboardJobLogItem } from '../DashboardItems/DashboardJobLogItem';
import { DashboardCard } from './DashboardCard';
import { JobLogClientEntry } from '~/server/schema/jobLog.schema';

interface Props {
	activeBranchIds: number[];
}

export const DashboardFeedCard = ({ activeBranchIds }: Props) => {
	const { ref, inView } = useInView();

	const {
		query: { isLoading },
		data: jobLogs,
		fetchNextPage,
	} = useInfinite(
		'jobLog',
		'allForDashboard',
		{
			branchIds: activeBranchIds,
			limit: 50,
		},
		{ enabled: inView }
	);

	const jobLogsGroupedByDate = useMemo(() => {
		const jobLogsByDate: Record<string, JobLogClientEntry[]> = {};

		jobLogs.forEach((jobLog) => {
			if (jobLog.date !== undefined) {
				const displayDate = DateHelper.formatAsTodayOrDate(jobLog.date);

				if (jobLogsByDate[displayDate]) {
					jobLogsByDate[displayDate]?.push(jobLog);
				} else {
					jobLogsByDate[displayDate] = [jobLog];
				}
			}
		});

		return jobLogsByDate;
	}, [jobLogs]);

	return (
		<DashboardCard ref={ref} icon="rectangle-history" title="Field Activity" isLoading={isLoading} fetchNextPage={fetchNextPage} isFullHeight>
			<div className="space-y-3">
				{Object.entries(jobLogsGroupedByDate).map(([displayDate, jobLogs]) => {
					return (
						<div key={displayDate.toString()}>
							<div className="relative flex justify-center items-center text-xs">
								<div className="absolute inset-0 flex items-center">
									<div className="h-px w-full bg-gray-200" />
								</div>
								<div className="flex items-center bg-white text-gray-500 font-medium z-10 px-4 py-1">{displayDate}</div>
							</div>
							<div>
								{jobLogs.map((jobLog, logIndex, logArray) => (
									<DashboardJobLogItem key={jobLog.summary} jobLog={jobLog} logIndex={logIndex} logLength={logArray.length} />
								))}
							</div>
						</div>
					);
				})}
			</div>
		</DashboardCard>
	);
};
